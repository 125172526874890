import { Suspense } from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import { ContactPage, HomePage } from "./Pages";
import { MainLayout } from "./Layout";

function App() {
  return (
    <>
      <Suspense fallback={<div className="preloader"></div>}>
        <Routes>
          <Route element={<MainLayout />}>
            <Route path="/" element={<HomePage />} />
            <Route path="/contact-us" element={<ContactPage />} />
          </Route>
        </Routes>
      </Suspense>
    </>
  );
}

export default App;
